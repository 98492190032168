export const WEEK = 604800;
export const YEAR = 31536000;
export const PAGE_SIZE = 10;
export const MODAL_PAGE_SIZE = 10;
export const MAX_IMAGE_UPLOAD_SIZE = 5; // MB
export const DROPS_PER_ML = [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
export const DROPS_PER_OZ = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const DEFAULT_DROPS_PREFERENCE = { drops_type: 1, drops_value: 30 };

export const dateFormat = "YYYY-MM-DD";

export const SHORT_MESSAGE_DELAY = 1;
export const LONG_MESSAGE_DELAY = 4;
export const DEFAULT_PRECISION = 2;

export const PREV_TOKEN_COOKIE = "prev_token";
export const TOKEN_COOKIE = "token";
export const REFRESH_TOKEN_COOKIE = "refresh_token";
export const LOCALE_COOKIE = "lang";
// (?=.*[$@$!%*?&])
export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{8,256}/;

export const SIDER_WIDTH = 75;

export const VOLUME_UNITS = ["l", "ml", "qt", "gal", "floz", "cup", "pnt", "Tbs", "tsp"];
export const LENGTH_UNITS = ["inch", "m", "mm", "cm"];
export const MASS_UNITS = ["kg", "g", "mg", "oz", "lb"];
export const SMALL_SCREEN_MAX_SIZE = 500;

// Raw material units
const _RAWMATERIAL_UNITS = [...VOLUME_UNITS, ...MASS_UNITS];
_RAWMATERIAL_UNITS.sort(function (a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
});
export const RAWMATERIAL_UNITS = ["drops", ..._RAWMATERIAL_UNITS];

export const COSMETIC_RAWMATERIAL_UNITS = ["g", "ml"];

// Component units
const _COMPONENT_UNITS = [...VOLUME_UNITS, ...MASS_UNITS, ...LENGTH_UNITS];
_COMPONENT_UNITS.sort(function (a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
});
export const COMPONENT_UNITS = ["pce", ..._COMPONENT_UNITS];

export const CURRENCIES = [
  {
    label: "EUR",
    key: "EUR",
    symbol: ["€", ""],
  },
  {
    label: "USD",
    key: "USD",
    symbol: ["$", ""],
  },
  {
    label: "GBP",
    key: "GBP",
    symbol: ["£", ""],
  },
];
