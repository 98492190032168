import { getUser, logout } from "../reducers/user";
import { generateUrl } from "../router";
//import {history} from "../history";

export const DR_ROUTE = "dr";
export const LOGIN_ROUTE = "login";
export const REGISTER_ROUTE = "register";
export const RESET_PASSWORD_ROUTE = "forgot-password";
export const SET_PASSWORD_ROUTE = "set-password";
export const PRIVACY_ROUTE = "privacy";
export const DISCLAIMER_ROUTE = "disclaimer";
export const TERMS_ROUTE = "terms";
export const CUSTOMERADMIN_ROUTE = "customeradmin";
export const USER_ROUTE = "user";
export const USER_PREFERENCES = "preferences";
export const AFFILIATE_PREFERENCES = "affiliate";
export const LIST_PRODUCTS_ROUTE = "listproducts";
export const RAW_INGREDIENTS_ROUTE = "rawingredients";
export const HOME_ROUTE = "home";
export const ABOUT_ROUTE = "about";
export const INVENTORY_ROUTE = "inventory";
export const RESOURCES_ROUTE = "resources";
export const REPORTS_ROUTE = "reports";

export const PRODUCT_ROUTE = "product";
export const LOGOUT_ROUTE = "logout";

const authRoutes = {
  path: "",
  children: [
  ],
};

// The top-level (parent) route
const routes = {
  path: "",

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: "/login",
      name: LOGIN_ROUTE,
      load: () => import(/* webpackChunkName: 'login' */ "./login"),
    },
    {
      path: "/register",
      name: REGISTER_ROUTE,
      load: () => import(/* webpackChunkName: 'register' */ "./register"),
    },
    {
      path: "/reset-password",
      name: RESET_PASSWORD_ROUTE,
      load: () => import(/* webpackChunkName: 'resetPassword' */ "./resetPassword"),
    },
    {
      path: "/set-password",
      name: SET_PASSWORD_ROUTE,
      load: () => import(/* webpackChunkName: 'setPassword' */ "./setPassword"),
    },
    {
      path: "/privacy-policy",
      name: PRIVACY_ROUTE,
      load: () => import(/* webpackChunkName: 'privacy' */ "./privacy"),
    },
    {
      path: "/disclaimer",
      name: DISCLAIMER_ROUTE,
      load: () => import(/* webpackChunkName: 'disclaimer' */ "./disclaimer"),
    },
    {
      path: "/terms",
      name: TERMS_ROUTE,
      load: () => import(/* webpackChunkName: 'terms' */ "./terms"),
    },
    {
      path: "/",
      name: DR_ROUTE,
      load: () => import(/* webpackChunkName: 'dr' */ "./dr"),
    },

    authRoutes,
    // Wildcard routes, e.g. { path: '*', ... } (must go last)
    {
      path: "(.*)",
      load: () => import(/* webpackChunkName: 'notFound' */ "./notFound"),
    },
  ],

  async action({ next, store }) {
    await store.dispatch(getUser());
    // Execute each child route until one of them return the result
    const route = await next();

    // force a complete reset of token and data
    // every time the login route is accessed
    if (route.chunks && route.chunks[0] === "login") {
      await store.dispatch(logout());
    }
    /* const tokenobj = getToken()
     * if (tokenobj) {
     *     console.log("token = ", tokenobj.cookies);
     *     const history = await process.env.BROWSER && createBrowserHistory();
     *
     *     console.log(" history = ", history);
     *     if (history.push) {
     *         history.push("/")
     *     }
     * } else {
     *     await store.dispatch(logout());
     * } */

    // Provide default values for title, description etc.
    route.title = `${route.title || "Blend Precisely"}`;
    route.description = route.description || "A software business toolkit for Aromatherapists, Herbalists & Formulators.";

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: "/error",
    action: require("./error").default,
  });
}

export default routes;
