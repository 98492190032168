import createReducer from "../createReducer";
// import {CURRENCIES} from '../constants';
import { getToken } from "./user";
import { handleCurrencyChange } from "../reducers/product";
import getSymbolFromCurrency from "currency-symbol-map";
// ------------------------------------
// Constants
// ------------------------------------
export const SET_CURRENT_PATHNAME = "Global.SET_CURRENT_PATHNAME";
export const SET_CURRENT_ROUTE_NAME = "Global.SET_CURRENT_ROUTE_NAME";

export const SET_CONFIG_VARS = "Global.SET_CONFIG_VARS";

export const CHANGE_CURRENCY = "Global.CHANGE_CURRENCY";

export const GET_CURRENCIES_REQUEST = "Global.GET_CURRENCIES_REQUEST";

export const GET_CURRENCIES_SUCCESS = "Global.GET_CURRENCIES_SUCCESS";

export const GET_CURRENCIES_FAILURE = "Global.GET_CURRENCIES_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
export const checkResponseStatus = ({ jsonresponse }) => {
  if (jsonresponse.status !== true) {
    return false;
  }
};

export const setConfigVars = ({ clientId, clientSecret, apiUrl, locales, appUrl }) => ({
  type: SET_CONFIG_VARS,
  clientId,
  clientSecret,
  apiUrl,
  locales,
  appUrl,
});

const getCurrenciesArray = (results) => {
  const currencyArray = [];

  let keyObj = {};
  results.forEach((item) => {
    keyObj.label = item.name;
    keyObj.key = item.name;
    keyObj.rate = item.rate;
    keyObj.id = item.id;
    keyObj.symbol = getSymbolFromCurrency(item.name);
    currencyArray.push(keyObj);
    keyObj = {};
  });
  return currencyArray;
};

export const setInitialCurrencies =
  () =>
  (dispatch, getState, { fetch }) => {
    // dispatch({type: GET_CURRENCIES_REQUEST});
    const { token } = dispatch(getToken());
    return fetch("/pands/currency/", {
      method: "GET",
      token,
      success: (res) => {
        const currenciesArray = getCurrenciesArray(res.results);
        dispatch({ type: GET_CURRENCIES_SUCCESS, currenciesArray });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: GET_CURRENCIES_FAILURE });
      },
    });
  };

export const setCurrentPathname = (currentPathname) => ({
  type: SET_CURRENT_PATHNAME,
  currentPathname,
});

export const setCurrentRouteName = (currentRouteName) => ({
  type: SET_CURRENT_ROUTE_NAME,
  currentRouteName,
});

export const changeCurrency = (key) => (dispatch, getState) => {
  const { currencies } = getState().global;

  if (!currencies || currencies.length < 1) {
    setInitialCurrencies();
  }
  const currency = currencies.find((item) => item.key === key);
  dispatch({ type: CHANGE_CURRENCY, currency });

  dispatch(handleCurrencyChange());
};

export const _changeCurrency = (key) => (dispatch, getState) => {
  const { currencies } = getState().global;

  if (!currencies || currencies.length <= 1) {
    setInitialCurrencies().then(() => {
      const currency = currencies.find((item) => item.key === key);
      dispatch({ type: CHANGE_CURRENCY, currency });
      dispatch(handleCurrencyChange());
    });
  } else {
    const currency = currencies.find((item) => item.key === key);
    dispatch({ type: CHANGE_CURRENCY, currency });
    dispatch(handleCurrencyChange());
  }
};

export const getSymbol = (selectedPreferencesCurrency) => {
  if (selectedPreferencesCurrency) {
    return selectedPreferencesCurrency.symbol;
  }
};

// look throught the currencies object for the desired 3 letter currency code
export const findCurrency = (currency_str) => (dispatch, getState) => {
  const { currencies } = getState().global;
  if (currency_str) {
    const cur = currencies.findIndex((x) => x.key == currency_str);
    return currencies[cur];
  }
};

export const getPriceFromUSD = (new_currency_key, current_usd_price) => {
  const curr = findCurrency(new_currency_key);
  // convert to desired currency
  return curr.rate * current_usd_price;
};

export const getFormattedPrice = (new_currency_key, price) => {
  const formatted = price.toLocaleString("en-US", {
    style: "currency",
    currency: new_currency_key,
  });
  return formatted;
};

export const getFormattedPriceFromUSD = (new_currency_key, current_price) => {
  const new_price = getPriceFromUSD(new_currency_key, current_price);
  const formatted = getFormattedPrice(new_currency_key, new_price);
  return formatted;
};

export const getPriceObjectFromUSD = (new_currency_key, current_price) => {
  const new_price = getPriceFromUSD(new_currency_key, current_price);
  const formatted = getFormattedPrice(new_currency_key, new_price);
  return { price: new_price, formatted: formatted };
};

// arbitrary conversion from any currency to a different currency
// e.g. EUR to JPY
export const convertCurrencyValue = (currencies, fromCur, value, toCur) => {
  if (currencies && fromCur && value && toCur) {
    const frm = findCurrency(fromCur);
    const to = findCurrency(toCur);

    return (value * to.rate) / frm.rate;
  }
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  currentPathname: null,
  currentRouteName: null,
  clientId: null,
  clientSecret: null,
  apiUrl: null,
  appUrl: null,
  apiBaseUrl: null,
  currency: {},
  currencies: [],
  locales: [],
};

const getDefaultCurrencyObj = (currencies) => {
  const currency = currencies.filter((currency) => currency.label === "USD");
  return currency[0];
};

export default createReducer(initialState, {
  [SET_CURRENT_PATHNAME]: (state, { currentPathname }) => ({
    currentPathname,
  }),
  [SET_CURRENT_ROUTE_NAME]: (state, { currentRouteName }) => ({
    currentRouteName,
  }),
  [SET_CONFIG_VARS]: (state, { clientId, clientSecret, apiUrl, locales, appUrl }) => ({
    clientId,
    clientSecret,
    apiUrl,
    locales,
    appUrl,
  }),
  [CHANGE_CURRENCY]: (state, { currency }) => ({
    currency: currency,
  }),
  [GET_CURRENCIES_SUCCESS]: (state, { currenciesArray }) => ({
    currencies: currenciesArray,
    currency: getDefaultCurrencyObj(currenciesArray),
  }),
});
