import compact from "lodash/compact";
import _ from "lodash";

export function noop() {}

// this is normally done by _.compact but that only works on arrays not objects
// TBD try using _.omit
export const removeFalsy = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] && !_.isEmpty(obj[prop]) && obj[prop] != [""]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

// generic currency conversion
export const ccur = (obj, three_letter_currency) => {
  if (obj) {
    return obj.toLocaleString("en-US", {
      style: "currency",
      currency: three_letter_currency,
    });
  } else {
    return "";
  }
};

export const getFormErrors = ({ values, errors }) => {
  if (errors.non_field_errors) {
    // error on alert
    return { error: errors.non_field_errors && errors.non_field_errors[0] };
  } else {
    // errors on form fields
    const formErrors = {};
    const fields = Object.keys(errors);
    fields.forEach((field) => {
      formErrors[field] = {
        value: values[field],
        errors: [
          {
            field,
            message: errors[field][0],
          },
        ],
      };
    });
    return { formErrors };
  }
};

export const getFieldValue = (items) => compact(items).filter((item) => !Object.values(item).some((v) => v === undefined));

// eslint-disable-next-line prefer-regex-literals
const whitespace = new RegExp("^\\s+$|^$");

export const isEmpty = (value) => {
  if (value === undefined || value === null || whitespace.test(value) || value === [] || value === "") {
    return true;
  } else {
    return false;
  }
};

export const truncate = (str, n) => {
  if (str) {
    let copy = str.slice();
    return copy.length > n ? copy.substr(0, n - 1) + "..." : copy;
  }
};

export const rmitem = (arr, item) => {
  const idx = arr.indexOf(item);
  return arr.splice(idx, 1);
};

export const arrattrs = (arr, attr) => {
  const newarr = [];
  if (arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      newarr.push(arr[i][attr]);
    }
  }
  return newarr;
};

// given an array of objects and an array of ids
//   get a list of the specified attr found in array_of_objects
export const arrObjAttr = (arr_of_objects, arr_of_ids, attr) => {
  let attrs = [];
  arr_of_ids.map((_id) => {
    const found = arr_of_objects.find((obj) => parseInt(obj.id) === parseInt(_id));
    if (found) attrs.push(found[attr]);
  });
  return attrs;
};

export const arrattrstr = (arr, attr) => {
  const filtered_arr = arrattrs(arr, attr);
  return filtered_arr ? filtered_arr.toString(", ") : "";
};

export const arrFilter = (arr, id) => {
  // remove item from array
  if (arr.includes(id)) {
    arr.splice(arr.indexOf(id), 1);
    // add item to an array
  } else {
    arr.push(id);
  }
  return arr;
};
