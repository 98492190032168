import createReducer, { RESET_STORE } from "../createReducer";
import { getToken } from "./user";
import qs from "query-string";
import _ from "lodash";

import { getPrices, deleteRowWithPrices } from "./recipe";
import { resetDilutionPercent } from "./dilution";
import { removeFalsy } from "../utils";

import { message } from "antd";
import { SHORT_MESSAGE_DELAY, LONG_MESSAGE_DELAY, PAGE_SIZE } from "../constants";

//const _ = require("lodash");
const querystring = require("querystring");
// ------------------------------------
// Constants
// ------------------------------------
export const SET_LAST_MODAL_TYPE = "RawMaterials.SET_LAST_MODAL_TYPE";

export const CLOSE_SHOW_INGREDIENTS_DRAWER = "RawMaterials.CLOSE_SHOW_INGREDIENTS_DRAWER";
export const OPEN_SHOW_INGREDIENTS_DRAWER = "RawMaterials.OPEN_SHOW_INGREDIENTS_DRAWER";

export const GET_RAW_MATERIALS_REQUEST = "RawMaterials.GET_RAW_MATERIALS_REQUEST";
export const GET_RAW_MATERIALS_SUCCESS = "RawMaterials.GET_RAW_MATERIALS_SUCCESS";
export const GET_RAW_MATERIALS_FAILURE = "RawMaterials.GET_RAW_MATERIALS_FAILURE";

export const GET_RAW_INGREDIENTS_REQUEST = "RawMaterials.GET_RAW_INGREDIENTS_REQUEST";
export const GET_RAW_INGREDIENTS_SUCCESS = "RawMaterials.GET_RAW_INGREDIENTS_SUCCESS";
export const GET_RAW_INGREDIENTS_FAILURE = "RawMaterials.GET_RAW_INGREDIENTS_FAILURE";

export const GET_RAW_SUPPLIER_REQUEST = "RawMaterials.GET_RAW_SUPPLIER_REQUEST";
export const GET_RAW_SUPPLIER_SUCCESS = "RawMaterials.GET_RAW_SUPPLIER_SUCCESS";
export const GET_RAW_SUPPLIER_FAILURE = "RawMaterials.GET_RAW_SUPPLIER_FAILURE";

export const PUSH_RAW_MATERIALS = "RawMaterials.PUSH_RAW_MATERIALS";

export const DELETE_RAW_MATERIALS_REQUEST = "RawMaterials.DELETE_RAW_MATERIALS_REQUEST";
export const DELETE_RAW_MATERIALS_SUCCESS = "RawMaterials.DELETE_RAW_MATERIALS_SUCCESS";
export const DELETE_RAW_MATERIALS_FAILURE = "RawMaterials.DELETE_RAW_MATERIALS_FAILURE";

export const UPDATE_COUNT = "RawMaterials.UPDATE_COUNT";

export const CHANGE_RAW_MATERIAL_SEARCH_MODAL = "RawMaterials.CHANGE_RAW_MATERIAL_SEARCH_MODAL";
export const CHANGE_SELECTED_ROWS = "RawMaterials.CHANGE_SELECTED_ROWS";

export const CHANGE_SELECTED_RAW_MATERIALS = "RawMaterials.CHANGE_SELECTED_RAW_MATERIALS";

export const UPDATE_SELECTED_RAW_MATERIALS = "RawMaterials.UPDATE_SELECTED_RAW_MATERIALS";

export const SAVE_FIELDS = "RawMaterials.SAVE_FIELDS";

export const CLEAR_SELECTED_RECIPES = "RawMaterials.CLEAR_SELECTED_RECIPES";

export const CLEAR = "RawMaterials.CLEAR";

export const LOADING_RECIPES = "RawMaterials.LOADING_RECIPES";

export const UPDATE_CATEGORIES = "RawMaterials.UPDATE_CATEGORIES";
1;
export const OPEN_ADD_INGREDIENT_MODAL = "RawMaterials.OPEN_ADD_INGREDIENT_MODAL";

export const CLOSE_ADD_INGREDIENT_MODAL = "RawMaterials.CLOSE_ADD_INGREDIENT_MODAL";

export const CLOSE_SUPPLIER_MODAL = "RawMaterials.CLOSE_SUPPLIER_MODAL";

export const OPEN_SUPPLIER_MODAL = "RawMaterials.OPEN_SUPPLIER_MODAL";

export const CLEAR_INGREDIENTS = "RawMaterials.CLEAR_INGREDIENTS";

export const SUBMIT_INGREDIENTS_REQUEST = "RawMaterials.SUBMIT_INGREDIENTS_REQUEST";

export const SUBMIT_INGREDIENTS_SUCCESS = "RawMaterials.SUBMIT_INGREDIENTS_SUCCESS";

export const SUBMIT_INGREDIENTS_FAILURE = "RawMaterials.SUBMIT_INGREDIENTS_FAILURE";

export const CLEAR_SUPPLIERS = "RawMaterials.CLEAR_SUPPLIERS";

export const UPDATE_CURRENCIES = "RawMaterials.UPDATE_CURRENCIES";

export const SUBMIT_SUPPLIER_REQUEST = "RawMaterials.SUBMIT_SUPPLIER_REQUEST";

export const SUBMIT_SUPPLIER_SUCCESS = "RawMaterials.SUBMIT_SUPPLIER_SUCCESS";

export const SUBMIT_SUPPLIER_FAILURE = "RawMaterials.SUBMIT_SUPPLIER_FAILURE";

export const GET_CATEGORIES_REQUEST = "RawMaterials.GET_CATEGORIES_REQUEST";

export const GET_CATEGORIES_SUCCESS = "RawMaterials.GET_CATEGORIES_SUCCESS";

export const GET_CATEGORIES_FAILURE = "RawMaterials.GET_CATEGORIES_FAILURE";

export const CLEAR_SELECTED_SUPPLIER = "RawMaterials.CLEAR_SELECTED_SUPPLIER";

export const SET_SCALEDBY = "RawMaterials.SET_SCALEDBY";

export const TOGGLE_ONLY_MINE = "RawMaterials.TOGGLE_ONLY_MINE";

export const SET_ONLY_MINE = "RawMaterials.SET_ONLY_MINE";

export const SELECTED_CATEGORY = "RawMaterials.SELECTED_CATEGORY";

export const TOGGLE_COSMETIC_MODE = "RawMaterials.TOGGLE_COSMETIC_MODE";

export const UPDATE_COSMETIC_MODE = "RawMaterials.UPDATE_COSMETIC_MODE";

export const UPDATE_COSMETIC_TOTAL_QUANTITY = "RawMaterials.UPDATE_COSMETIC_TOTAL_QUANTITY";
export const UPDATE_COSMETIC_TOTAL_RECIPE_UNIT = "RawMaterials.UPDATE_COSMETIC_TOTAL_RECIPE_UNIT";

// ------------------------------------
// Actions
// ------------------------------------

export const changeRawMaterialSearch = () => ({
  type: CHANGE_RAW_MATERIAL_SEARCH_MODAL,
});

export const getSuppliersForTable =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_RAW_SUPPLIER_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch(
      `/pands/suppliers/?${qs.stringify({
        search,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          const supplier = res.results;
          const count = res.results.length;
          dispatch({ type: GET_RAW_SUPPLIER_SUCCESS, supplier, count });
        },
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => {
          dispatch({ type: GET_RAW_SUPPLIER_FAILURE });
        },
      }
    );
  };

export const handleDeleteRow =
  (id) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    dispatch({ type: DELETE_RAW_MATERIALS_REQUEST });
    return fetch(`/pands/raw-materialids/${id}/`, {
      method: "DELETE",
      token,
      success: (res) => {
        dispatch(getRawMaterials());
        dispatch({ type: DELETE_RAW_MATERIALS_SUCCESS, res });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => dispatch({ type: DELETE_RAW_MATERIALS_FAILURE }),
    });
  };

export const updateRawMaterialDropdownProperties =
  (id, property, propertyType) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const actualRawMaterials = getState().rawMaterials.rawMaterials;
    const requestObj = {};
    actualRawMaterials.forEach((rawMaterialObj) => {
      if (rawMaterialObj.id !== id) return null;
      switch (propertyType) {
        case "Supplier":
          requestObj.supplier = property;
          rawMaterialObj.supplier = getState().rawMaterials.suppliers.filter((supplier) => supplier.id === property)[0];
          break;
        case "Quantity_unit":
          requestObj.quantity_unit = property;
          rawMaterialObj.quantity_unit = property;
          break;
        case "Currency":
          requestObj.currency = property;
          rawMaterialObj.currency = getState().global.currencies.filter((currency) => currency.id === property)[0];
          break;
        default:
          break;
      }
    });
    return fetch("/pands/raw-materialids/" + id + "/", {
      method: "PATCH",
      token,
      body: {
        ...requestObj,
      },
      success: (res) => {
        dispatch(getRawMaterials());
        message.success(propertyType + " Updated Successfully", SHORT_MESSAGE_DELAY);
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        message.error("Unable to update " + propertyType + " , Please try again later.", LONG_MESSAGE_DELAY);
      },
    });
  };

export const updateExpirationDate =
  (id, date) =>
  (dispatch, getState, { fetch }) => {
    const obj = { exp_date: date };
    const { token } = dispatch(getToken());
    return fetch("/pands/raw-materialids/" + id + "/", {
      method: "PATCH",
      token,
      body: {
        ...obj,
      },
      success: (res) => {
        message.success("Expiration Date Updated Successfully", SHORT_MESSAGE_DELAY);
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        message.error("Unable to update Expiration Date, " + JSON.stringify(err), LONG_MESSAGE_DELAY);
      },
    });
  };

export const updateRawMaterialProperties =
  (id, property, currentText, propertyType) =>
  (dispatch, getState, { fetch }) => {
    const floatPrevTextValue = parseFloat(currentText).toFixed(3);
    const floatCurrentTextValue = parseFloat(property).toFixed(3);
    if (floatPrevTextValue === floatCurrentTextValue) return null;
    const { token } = dispatch(getToken());
    const actualRawMaterials = getState().rawMaterials.rawMaterials;
    const requestObject = {};
    actualRawMaterials.forEach((rawMaterialObj) => {
      if (rawMaterialObj.id !== id) return null;
      switch (propertyType) {
        case "Price":
          rawMaterialObj.price = property;
          requestObject.price = property;
          break;
        case "Quantity":
          rawMaterialObj.quantity = property;
          requestObject.quantity = property;
          break;
        default:
          break;
      }
    });
    return fetch("/pands/raw-materialids/" + id + "/", {
      method: "PATCH",
      token,
      body: {
        ...requestObject,
      },
      success: (res) => {
        dispatch(getRawMaterials());
        message.success(propertyType + " Updated Successfully", SHORT_MESSAGE_DELAY);
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        message.error("Unable to update" + propertyType + ", Please try again later.", LONG_MESSAGE_DELAY);
      },
    });
  };

export const submitSupplier =
  (obj) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    dispatch({ type: SUBMIT_SUPPLIER_REQUEST });

    return fetch("/pands/suppliers/ ", {
      method: "POST",
      token,
      body: {
        ...obj,
      },
      success: (res) => {
        message.success("Supplier Successfully added", SHORT_MESSAGE_DELAY);
        const selectedSupplier = res;

        dispatch({ type: SUBMIT_SUPPLIER_SUCCESS, selectedSupplier });
        dispatch(getSuppliers({ page: 1, afterUpdate: true }));
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        if (err.non_field_errors[0].includes("unique")) {
          message.error("Supplier already exists", LONG_MESSAGE_DELAY);
        } else {
          message.error("Unable to add Supplier , Please try later", LONG_MESSAGE_DELAY);
        }
        dispatch({ type: SUBMIT_SUPPLIER_FAILURE });
      },
    });
  };

export const clearSelectedSupplier = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_SELECTED_SUPPLIER });
};

export const currencySearch = (search) => (dispatch, getState) => {
  const { currencies } = getState().global;
  let newCurrency = currencies;
  if (search === "") {
    dispatch({ type: UPDATE_CURRENCIES, newCurrency });
    return;
  }
  newCurrency = currencies.map((currency) => {
    if (currency.label.search(new RegExp(search, "i")) !== -1) return currency;
    return null;
  });
  newCurrency = newCurrency.filter((i) => i);
  dispatch({ type: UPDATE_CURRENCIES, newCurrency });
};

export const clearIngredients = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_INGREDIENTS });
};
export const setSelectedCategory = (selectedCategory) => (dispatch, getState) => {
  dispatch({ type: SELECTED_CATEGORY, selectedCategory });
};

export const submitIngredient =
  (obj) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    dispatch({ type: SUBMIT_INGREDIENTS_REQUEST });

    return fetch("/pands/raw-materialids/", {
      method: "POST",
      token,
      body: {
        ...obj,
      },
      success: (res) => {
        message.success("Raw Ingredient Successfully added", SHORT_MESSAGE_DELAY);
        dispatch({ type: SUBMIT_INGREDIENTS_SUCCESS });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        message.success("Unable to add Raw Material , Please try again later.", LONG_MESSAGE_DELAY);
        dispatch({ type: SUBMIT_INGREDIENTS_FAILURE });
      },
    });
  };

export const getIngredients =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_RAW_INGREDIENTS_REQUEST, params });
    const { ingredients, totalIngredients } = getState().rawMaterials;
    // if the length is the total dont process any more
    if (totalIngredients !== 0 && ingredients.length === totalIngredients) return;
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = params.page_size ? params.page_size : undefined;

    return fetch(
      `/pands/raw-ingredients/?${qs.stringify({
        search,
        page,
        page_size,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          let value;
          let count = totalIngredients;
          if (!params.search) {
            count = res.count;
          }
          if (params.search && params.specialSearch) {
            value = res.results;
          } else {
            value = ingredients.concat(res.results);
          }
          dispatch({ type: GET_RAW_INGREDIENTS_SUCCESS, value, count });
        },
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => {
          dispatch({ type: GET_RAW_INGREDIENTS_FAILURE });
        },
      }
    );
  };

export const getFilteredIngredients =
  (params, filters) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_RAW_INGREDIENTS_REQUEST, params });
    const { ingredients, totalIngredients } = getState().rawMaterials;
    const search = params.search ? params.search : undefined;

    const { token } = dispatch(getToken());
    //            let { search, ordering } = getState().product;
    const page = params.page ? params.page : undefined;
    const page_size = params.page_size ? params.page_size : undefined;

    const filtered = removeFalsy(filters);

    let params_qs = "";
    let filter_qs = "";

    if (_.isEmpty(params)) {
      params_qs = params_qs;
    } else {
      params_qs = new URLSearchParams(params).toString();
    }

    if (_.isEmpty(filtered)) {
      filter_qs = filter_qs;
    } else {
      filter_qs = new URLSearchParams(filtered).toString();
    }

    const qs = params_qs + "&" + filter_qs;

    return fetch(`/pands/raw-ingredients/?${qs}`, {
      method: "GET",
      token,
      success: (res) => {
        let count = res.count;
        let value = res.results;
        dispatch({ type: GET_RAW_INGREDIENTS_SUCCESS, value, count });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: GET_RAW_INGREDIENTS_FAILURE });
      },
    });
  };

export const clearSuppliers = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_SUPPLIERS });
};

export const getSuppliers =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    const { suppliers, totalSuppliers } = getState().rawMaterials;

    if (totalSuppliers !== 0 && suppliers.length === totalSuppliers && !params.afterUpdate) return;
    dispatch({ type: GET_RAW_SUPPLIER_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;

    return fetch(
      `/pands/suppliers/?${qs.stringify({
        search,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          if (params.afterUpdate) {
            const supplier = res.results;
            let count = totalSuppliers;
            if (!params.search) count = res.count;
            dispatch({ type: GET_RAW_SUPPLIER_SUCCESS, supplier, count });
            return;
          }
          const supplier = suppliers.concat(res.results);
          let count = totalSuppliers;
          if (!params.search) count = res.count;
          dispatch({ type: GET_RAW_SUPPLIER_SUCCESS, supplier, count });
        },
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => {
          dispatch({ type: GET_RAW_SUPPLIER_FAILURE });
        },
      }
    );
  };

export const getRawMaterialsForSelectedCategory = (selectedCategory, onlymy) => (dispatch, getState) => {
  const { categories } = getState().rawMaterials;
  const category = categories.filter((i) => i.name === selectedCategory);
  const mine = onlymy ? 1 : 0;
  if (!category.length) {
    dispatch(
      getRawMaterials({
        cat: undefined,
        page: 1,
        search: undefined,
        only_mine: mine,
      })
    );
  } else {
    dispatch(
      getRawMaterials({
        cat: category[0].id,
        page: 1,
        search: undefined,
        only_mine: mine,
      })
    );
  }
};

export const getRawMaterials =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_RAW_MATERIALS_REQUEST, params });
    const { token } = dispatch(getToken());
    dispatch(loadingRecipes());
    const page_size = params.page_size ? params.page_size : undefined;
    const { search, ordering, only_mine, page, cat } = getState().rawMaterials;

    return fetch(
      `/pands/raw-materials/?${qs.stringify({
        search,
        ordering,
        page,
        page_size,
        only_mine,
        cat,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          const ingredients = res.results;
          if (params.ispush) dispatch({ type: PUSH_RAW_MATERIALS, ingredients });
          else dispatch({ type: GET_RAW_MATERIALS_SUCCESS, ingredients });

          // if( !page &&  !page_size){
          // const categories = getCategories(rawMaterials);
          // const count = res.count;
          // const existingCategories = getState().rawMaterials.categories
          // var is_same = existingCategories.length == categories.length && categories.every(function(element) {
          //   return _.includes(existingCategories, element)
          // });
          // if(!is_same) dispatch({type:UPDATE_CATEGORIES,categories,count})
          // }else{
          const count = res.count;
          dispatch({ type: UPDATE_COUNT, count });

          // }
        },
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => dispatch({ type: GET_RAW_MATERIALS_FAILURE }),
      }
    );
  };

export const getRawIngredients =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_RAW_MATERIALS_REQUEST, params });
    const { token } = dispatch(getToken());
    dispatch(loadingRecipes());
    const page_size = params.page_size ? params.page_size : undefined;
    const { search, ordering, only_mine, page, cat } = getState().rawMaterials;

    return fetch(
      `/pands/raw-materials/?${qs.stringify({
        search,
        ordering,
        page,
        page_size,
        only_mine,
        cat,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          const ingredients = res.results;
          if (params.ispush) dispatch({ type: PUSH_RAW_MATERIALS, ingredients });
          else dispatch({ type: GET_RAW_MATERIALS_SUCCESS, ingredients });

          // if( !page &&  !page_size){
          // const categories = getCategories(rawMaterials);
          // const count = res.count;
          // const existingCategories = getState().rawMaterials.categories
          // var is_same = existingCategories.length == categories.length && categories.every(function(element) {
          //   return _.includes(existingCategories, element)
          // });
          // if(!is_same) dispatch({type:UPDATE_CATEGORIES,categories,count})
          // }else{
          const count = res.count;
          dispatch({ type: UPDATE_COUNT, count });

          // }
        },
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => dispatch({ type: GET_RAW_MATERIALS_FAILURE }),
      }
    );
  };

export const getCategories =
  () =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    dispatch({ type: GET_CATEGORIES_REQUEST });
    return fetch("/pands/raw-materials/categories/", {
      method: "GET",
      token,
      success: (res) => {
        dispatch({ type: GET_CATEGORIES_SUCCESS, res });
        //                      let defaultCategoryID = res.filter(category => category.name === "Carrier Oil")
        //                      dispatch(getRawMaterials({cat:defaultCategoryID[0].id,page:1,search:undefined}))
        //                      http://localhost:8000/api/pands/raw-materials/?only_mine=0&page=2
        dispatch(getRawMaterials({ page: 1, search: undefined }));
      },
      failure: (res) => {
        dispatch({ type: GET_CATEGORIES_FAILURE });
      },
    });
  };

export const getSpecificRawMaterials =
  (id) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const params = {};
    dispatch({ type: GET_RAW_MATERIALS_REQUEST, params });
    return fetch(`/pands/raw-materials/?cat=${id}/`, {
      token,
      method: "GET",
      success: (res) => {
        const ingredients = res.results;
        dispatch({ type: GET_RAW_MATERIALS_SUCCESS, ingredients });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: GET_RAW_MATERIALS_FAILURE });
      },
    });
  };

// doubt here, function get return after forEach
// eslint-disable-next-line no-unused-vars
const getCategoriesold = (res) => {
  const categories = [];

  const result = res;
  // eslint-disable-next-line array-callback-return,no-unused-vars
  const value = result.map((rawMaterial, index) => {
    if (rawMaterial.category.length > 0) {
      if (_.includes(categories, rawMaterial.raw_ingredient.category[0].name)) return null;
      categories[index] = rawMaterial.raw_ingredient.category[0].name;
      return rawMaterial.raw_ingredient.category[0].name;
    } else if (rawMaterial.raw_ingredient.category.length === 0) {
      if (_.includes(categories, "Others")) return null;
      categories.push("Others");
    }
  });
  const sorterArray = categories.sort();
  if (sorterArray.includes("Others")) {
    sorterArray.splice(sorterArray.indexOf("Others"), 1);
    sorterArray.push("Others");
  }

  return categories;
};

export const openSupplierModal = () => (dispatch, getState) => {
  dispatch({ type: OPEN_SUPPLIER_MODAL });
};

export const closeSupplierModal = () => ({
  type: CLOSE_SUPPLIER_MODAL,
});

export const openIngredientsDrawer = () => ({
  type: OPEN_SHOW_INGREDIENTS_DRAWER,
});

export const closeIngredientsDrawer = () => ({
  type: CLOSE_SHOW_INGREDIENTS_DRAWER,
});

export const openAddIngredientsModal = () => (dispatch, getState) => {
  dispatch({ type: OPEN_ADD_INGREDIENT_MODAL });
};

export const closeAddIngredientsModal = () => (dispatch, getState) => {
  dispatch({ type: CLOSE_ADD_INGREDIENT_MODAL });
};

export const changeSelectedRows = (keys, selectedRows) => ({
  type: CHANGE_SELECTED_ROWS,
  selectedRows,
});

export const handleDeleteAll =
  (ids, pagenumber, alreadyExist) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    dispatch({ type: DELETE_RAW_MATERIALS_REQUEST });
    return fetch("/pands/deleterawmaterials/", {
      method: "DELETE",
      token,
      body: {
        ids: ids,
      },
      success: (res) => {
        dispatch(getRawMaterials({ page: pagenumber, page_size: 50 }));
        dispatch({ type: DELETE_RAW_MATERIALS_SUCCESS, res });
        if (alreadyExist.length > 0) {
          return message.success("Ingredients deleted.", LONG_MESSAGE_DELAY);
        }
        message.success("Deleted all selected Ingredients");
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        message.error("Deleting failed, Please try later", SHORT_MESSAGE_DELAY);
        dispatch({ type: DELETE_RAW_MATERIALS_FAILURE });
      },
    });
  };

export const updateSelectedRawMaterials = (selectedRawMaterials) => (dispatch, getState) => {
  dispatch({ type: UPDATE_SELECTED_RAW_MATERIALS, selectedRawMaterials });
};

export const changeSelectedRawMaterials = (selectedRawMaterials) => (dispatch, getState) => {
  dispatch({ type: CHANGE_SELECTED_RAW_MATERIALS, selectedRawMaterials });
};

export const selectRawMaterials = (newRows) => (dispatch, getState) => {
  const { selectedRawMaterials } = getState().rawMaterials;

  const newRawMaterials = newRows.map((item) => ({ raw_material: item }));
  dispatch(changeSelectedRawMaterials(_.uniqBy([...selectedRawMaterials, ...newRawMaterials], "raw_material.id")));
  // load whole list of items
  // dispatch(getRawMaterials({ search: undefined }));
};

export const deselectRawMaterial = (removedRow) => (dispatch, getState) => {
  const { selectedRawMaterials } = getState().rawMaterials;
  dispatch(
    changeSelectedRawMaterials(
      selectedRawMaterials.filter((item) => {
        if (!item || (item && !item.raw_material)) return;
        return item.raw_material.id !== removedRow.raw_material.id;
      })
    )
  );
  dispatch(deleteRowWithPrices(removedRow.raw_material.id));
};

export const onScaledByChange = (value) => (dispatch, getState) => {
  const selectedProduct = getState().product.selectedProduct;
  if (!selectedProduct) {
    return;
  }

  const selectedRawMaterials = getState().rawMaterials.selectedRawMaterials;
  const fields = getState().rawMaterials.fields;
  const ingredients = fields && fields.ingredients;
  const scaledBy = getState().rawMaterials.scaledBy;
  if (ingredients) {
    // eslint-disable-next-line array-callback-return
    ingredients.map((ingredient) => {
      if (selectedRawMaterials) {
        // eslint-disable-next-line array-callback-return
        selectedRawMaterials.map((rm) => {
          if (rm.raw_material.id === ingredient.raw_material) {
            let quantity = ingredient.quantity;
            if (quantity) {
              quantity = parseFloat((quantity / scaledBy) * value).toFixed(2);
            }
            ingredient.quantity = quantity;
          }
        });
      }
    });
  }
  fields.ingredients = ingredients;
  dispatch({ type: SAVE_FIELDS, fields });
};

export const saveFields = (fields, changedFields) => (dispatch, getState) => {
  dispatch({ type: SAVE_FIELDS, fields });
  const ingredientsChanged = Object.keys(changedFields).find((key) => key.includes("ingredients"));
  if (ingredientsChanged) {
    dispatch(getPrices(fields));
  } else {
    dispatch(getPrices(fields));
    dispatch(resetDilutionPercent());
  }
};

export const saveCosmeticFields = (fields, changedFields) => (dispatch, getState) => {
  dispatch({ type: SAVE_FIELDS, fields });
  const blendChanged =
    Object.keys(changedFields).find((key) => key.includes("ingredients")) ||
    Object.keys(changedFields).find((key) => key.includes("recipe_total")) ||
    Object.keys(changedFields).find((key) => key.includes("recipe_totalunit"));
  if (blendChanged) {
    dispatch(getPrices(fields));
  } else {
    dispatch(getPrices(fields));
    dispatch(resetDilutionPercent());
  }
};

export const saveScaledBy = (value) => (dispatch, getState) => {
  dispatch({ type: SET_SCALEDBY, value });
};

export const clearOnlySelectedRecipes = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_SELECTED_RECIPES });
  const { user } = getState().user;
  user && user.preferences && dispatch(toggleCosmeticMode(user.preferences.is_show_cosmetic_mode));
};

export const clear = () => {
  return { type: CLEAR };
};

export const loadingRecipes = () => (dispatch, getState) => {
  let { recipesLoad } = getState().rawMaterials;
  recipesLoad = true;
  dispatch({ type: LOADING_RECIPES, recipesLoad });
};

export const handleDelete =
  (id) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    dispatch({ type: DELETE_RAW_MATERIALS_REQUEST });
    return fetch(`/pands/raw-materials/${id}/`, {
      method: "DELETE",
      token,
      success: (res) => {
        dispatch(getRawMaterials());
        dispatch({ type: DELETE_RAW_MATERIALS_SUCCESS, res });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => dispatch({ type: DELETE_RAW_MATERIALS_FAILURE }),
    });
  };

export const toggleCosmeticMode = (checked) => (dispatch) => {
  dispatch({ type: TOGGLE_COSMETIC_MODE, mode: checked });
};

export const updateCosmeticMode = (res) => (dispatch) => {
  if (res && res.recipe) {
    dispatch({ type: UPDATE_COSMETIC_MODE, mode: res.recipe.cosmetic });
    dispatch(updateCosmeticTotalRecipeQuantity(res.recipe.total));
    dispatch(updateCosmeticTotalRecipeUnit(res.recipe.totalunit));
  }
};

export const updateCosmeticTotalRecipeQuantity = (value) => (dispatch) => {
  dispatch({ type: UPDATE_COSMETIC_TOTAL_QUANTITY, quantityValue: value });
};

export const updateCosmeticTotalRecipeUnit = (value) => (dispatch) => {
  dispatch({ type: UPDATE_COSMETIC_TOTAL_RECIPE_UNIT, unit: value });
};

export const toggleOnlyMine = (onlyMineBasedOnPreference) => (dispatch, getState) => {
  dispatch({ type: TOGGLE_ONLY_MINE, onlyMineBasedOnPreference });
};

export const setOnlyMine = (onlyMineBasedOnPreference) => (dispatch, getState) => {
  dispatch({ type: SET_ONLY_MINE, onlyMineBasedOnPreference });
};

export const setLastModalType = (lastmodaltype) => (dispatch, getState) => {
  dispatch({ type: SET_LAST_MODAL_TYPE, lastmodaltype });
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  AddIngredientsModalOpened: false,
  cat: undefined,
  categories: [],
  categoriesLoad: false,
  cosmetic: false,
  cosmeticRecipeUnit: "g",
  cosmeticTotalRecipeQuantity: 100,
  cosmetictotalpercent: 0,
  cosmeticunit: "g",
  rawMaterialSearch: undefined,
  count: 0,
  fields: {},
  filters: {},
  ingredients: [],
  ingredientsLoad: false,
  loading: false,
  lastmodaltype: "table",
  only_mine: 0,
  ordering: undefined,
  page: 1,
  rawMaterials: [],
  rawMaterialsModal: [],
  rawMaterialsModalOpened: false,
  recipesLoad: false,
  scaledBy: 1,
  search: undefined,
  selectedCategory: "all",
  selectedCurrencies: [],
  selectedRawMaterials: [],
  selectedRows: [],
  selectedStockRawMaterials: [],
  selectedSupplier: {},
  submitIngredientLoader: false,
  supplierModalOpened: false,
  suppliers: [],
  suppliersLoad: false,
  totalIngredients: 0,
  totalSuppliers: 0,
  showIngredientsDrawer: false,
};

export default createReducer(initialState, {
  [GET_RAW_MATERIALS_REQUEST]: (state, { params }) => ({
    search: _.has(params, "search") ? params.search : state.search,
    ordering: params.sorter ? `${params.sorter.order === "descend" ? "-" : ""}${params.sorter.columnKey}` : state.ordering,
    // page: params.page ? params.page:state.page,
    filters: params.filters || state.filters,
    loading: true,
    only_mine: params.only_mine === 1 || params.only_mine === 0 ? params.only_mine : state.only_mine,
    cat: params.cat ? (params.cat === 1000 ? undefined : params.cat) : params.cat === 0 ? undefined : state.cat,
    page: params.page ? params.page : state.page,
  }),
  [UPDATE_CURRENCIES]: (state, action) => ({
    selectedCurrencies: action.newCurrency,
  }),
  [SELECTED_CATEGORY]: (state, action) => ({
    selectedCategory: action.selectedCategory,
  }),
  [PUSH_RAW_MATERIALS]: (state, action) => ({
    rawMaterials: state.rawMaterials.concat(action.ingredients),
    loading: false,
    recipesLoad: false,
  }),
  [GET_CATEGORIES_REQUEST]: (state, action) => ({
    categoriesLoad: true,
  }),
  [GET_CATEGORIES_SUCCESS]: (state, action) => ({
    categoriesLoad: false,
    categories: action.res,
  }),
  [GET_CATEGORIES_FAILURE]: (state, action) => ({
    categoriesLoad: false,
  }),
  [SUBMIT_INGREDIENTS_REQUEST]: (state, action) => ({
    submitIngredientLoader: true,
  }),
  [SUBMIT_INGREDIENTS_SUCCESS]: (state, action) => ({
    submitIngredientLoader: false,
  }),
  [SUBMIT_INGREDIENTS_FAILURE]: (state, action) => ({
    submitIngredientLoader: false,
  }),
  [OPEN_ADD_INGREDIENT_MODAL]: (state, action) => ({
    AddIngredientsModalOpened: true,
  }),
  [CLOSE_ADD_INGREDIENT_MODAL]: (state, action) => ({
    AddIngredientsModalOpened: false,
  }),
  [CHANGE_RAW_MATERIAL_SEARCH_MODAL]: (state, { rawMaterialSearch }) => ({
    rawMaterialSearch,
  }),

  [UPDATE_COUNT]: (state, action) => ({ count: action.count }),
  [GET_RAW_INGREDIENTS_REQUEST]: (state, { params }) => ({
    ingredientsLoad: true,
    // ingredients: params.specialSearch ? []:state.ingredients
  }),

  [GET_RAW_INGREDIENTS_FAILURE]: (state, action) => ({
    ingredientsLoad: false,
  }),
  [GET_RAW_INGREDIENTS_SUCCESS]: (state, action) => ({
    ingredientsLoad: false,
    ingredients: action.value,
    totalIngredients: action.count,
  }),
  // [GET_RAW_MATERIALS_SUCCESS]: (state, { res: { results } }) => ({
  //   rawMaterials: results,
  //   loading: false,
  //   recipesLoad: false
  // }),
  [GET_RAW_MATERIALS_SUCCESS]: (state, { ingredients }) => ({
    rawMaterials: ingredients,
    loading: false,
    recipesLoad: false,
  }),
  [GET_RAW_MATERIALS_FAILURE]: (state, action) => ({
    loading: false,
    recipesLoad: false,
  }),
  [CLEAR_SUPPLIERS]: (state, action) => ({
    suppliers: [],
  }),
  [CLEAR_SELECTED_SUPPLIER]: (state, action) => ({
    selectedSupplier: {},
  }),
  [UPDATE_CATEGORIES]: (state, { categories, count }) => ({
    // categories:categories,
    count: count,
  }),
  [GET_RAW_SUPPLIER_REQUEST]: (state, action) => ({
    suppliersLoad: true,
  }),
  [GET_RAW_SUPPLIER_SUCCESS]: (state, action) => ({
    suppliersLoad: false,
    suppliers: action.supplier,
    totalSuppliers: action.count,
  }),
  [GET_RAW_SUPPLIER_FAILURE]: (state, action) => ({
    suppliersLoad: false,
  }),
  [CHANGE_SELECTED_ROWS]: (state, { selectedRows }) => ({
    selectedRows,
  }),
  [CHANGE_SELECTED_RAW_MATERIALS]: (state, { selectedRawMaterials }) => ({
    selectedRawMaterials,
  }),
  [UPDATE_SELECTED_RAW_MATERIALS]: (state, { selectedRawMaterials }) => ({
    selectedRawMaterials,
  }),
  [CLEAR_INGREDIENTS]: (state, action) => ({
    ingredients: [],
    totalIngredients: 0,
  }),
  [SAVE_FIELDS]: (state, { fields }) => ({
    fields: {
      ...state.fields,
      ...fields,
    },
  }),
  [LOADING_RECIPES]: (state, { recipesLoad }) => ({
    recipesLoad,
  }),
  [DELETE_RAW_MATERIALS_REQUEST]: (state, action) => ({
    loading: true,
  }),
  [OPEN_SUPPLIER_MODAL]: (state, action) => ({
    supplierModalOpened: true,
  }),
  [CLOSE_SUPPLIER_MODAL]: (state, action) => ({
    supplierModalOpened: false,
  }),
  [OPEN_SHOW_INGREDIENTS_DRAWER]: (state, action) => ({
    showIngredientsDrawer: true,
  }),
  [CLOSE_SHOW_INGREDIENTS_DRAWER]: (state, action) => ({
    showIngredientsDrawer: false,
  }),
  [DELETE_RAW_MATERIALS_SUCCESS]: (state, action) => ({
    loading: false,
  }),
  [DELETE_RAW_MATERIALS_FAILURE]: (state, action) => ({
    loading: false,
  }),
  [CLEAR_SELECTED_RECIPES]: (state, action) => ({
    rawMaterialSearch: undefined,
    selectedRows: [],
    selectedRawMaterials: [],
    cosmeticTotalRecipeQuantity: 100,
    scaledBy: 1,
  }),
  [SUBMIT_SUPPLIER_SUCCESS]: (state, action) => ({
    selectedSupplier: action.selectedSupplier,
  }),
  [SET_SCALEDBY]: (state, { value }) => ({
    scaledBy: value,
  }),
  [TOGGLE_COSMETIC_MODE]: (state, { mode }) => ({
    cosmetic: mode,
  }),
  [UPDATE_COSMETIC_MODE]: (state, { mode }) => ({
    cosmetic: mode,
  }),
  [CLEAR]: (state, action) => RESET_STORE,
  [SET_ONLY_MINE]: (state, { onlyMineBasedOnPreference }) => ({
    only_mine: onlyMineBasedOnPreference,
  }),
  [TOGGLE_ONLY_MINE]: (state, { onlyMineBasedOnPreference }) => ({
    only_mine: onlyMineBasedOnPreference || !state.only_mine,
  }),
  [UPDATE_COSMETIC_TOTAL_QUANTITY]: (state, { quantityValue }) => ({
    cosmeticTotalRecipeQuantity: quantityValue,
  }),
  [UPDATE_COSMETIC_TOTAL_RECIPE_UNIT]: (state, { unit }) => ({
    cosmeticRecipeUnit: unit,
  }),
  [SET_LAST_MODAL_TYPE]: (state, { lastmodaltype }) => ({
    lastmodaltype: lastmodaltype,
  }),
});
